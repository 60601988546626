<template>
  <div :class="$style.wrap">
    <mly-header style2></mly-header>

    <top :game="game"></top>

    <section :class="$style.banner">
      <img :src="this.game.mediumImg" alt>
    </section>
    <section :class="$style.introduce">
      <h3>游戏介绍：</h3>
      <p>{{game.content}}</p>
    </section>

    <screenshot :screenshots="screenshots"></screenshot>

    <footer class="clearfix">
      <img src="@/assets/pic_yxqz.png" alt>
      <div></div>
      <img src="@/assets/pic_yxlb.png" alt>
    </footer>
  </div>
</template>
<script>
import axios from "axios";
import Const from "../../commons/const.js";
import top from "./top";
import screenshot from "./screenshot";

export default {
  props: ["game_id"],
  components: {
    top,
    screenshot
  },
  created() {
    this.loadData();
  },

  watch: {
    game_id() {
      this.loadData();
    }
  },
  data() {
    return {
      game: {}
    };
  },
  computed: {
    screenshots: function() {
      if (!this.game.gameScreenshot) {
        return [];
      }
      return this.game.gameScreenshot.split(",");
    }
  },
  methods: {
    loadData() {
      axios
        .get("/game/getGameById", {
          params: {
            id: this.game_id
          }
        })
        .then(response => {
          if (response.returnCode == Const.RequestSuccess) {
            this.game = response.returnData;
          }
        });
    }
  }
};
</script>
<style module>
.wrap h3 {
  margin: 0;
}

.banner {
  min-height: 3rem;
}

.banner img {
  width: 100%;
  height: 100%;
}

.introduce {
  border-bottom: 0.2rem #f4f4f4 solid;
  padding: 0.3rem 0.4rem;
}

.introduce h3 {
  font-size: 0.3rem;
  margin-bottom: 0.15rem;
}

.introduce p {
  text-indent: 2em;
  font-size: 0.22rem;
  line-height: 0.37rem;
}

.wrap footer {
  margin: 0.32rem 0.2rem;
}

.wrap footer div,
.wrap footer img {
  float: left;
}

.wrap footer div {
  width: 4%;
  height: 0.88rem;
}

.wrap footer img {
  width: 48%;
  height: 0.88rem;
}
</style>
