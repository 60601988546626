<template>
  <section :class="$style.wrap" class="list-item">
    <img :src="game.icon" alt class="img-rounded img-responsive">
    <div class="description">
      <p>{{game.name}}</p>
      <p>{{game.slogan}}</p>
    </div>
    <button type="button" @click="downGame(game)">立即下载</button>
  </section>
</template>

<script>
import util from "../../commons/util.js";
import Const from "../../commons/const.js";

export default {
  props: ["game"],
  methods: {
    downGame(game) {
      if (device.ios()) {
        if (util.isEmpty(game.iosDownUrl)) {
          util.toast("上线在即，敬请期待^^");
        } else {
          window.open(game.iosDownUrl, "_blank");
        }
      } else {
        if (Const.weixin) {
          util.wxtip();
        } else {
          window.open(game.downUrl, "_blank");
        }
      }
    }
  }
};
</script>

<style module>
.wrap {
  background-color: #333333;
  padding: 0.12rem 0.25rem;
  border: none;
}

.wrap p {
  color: white !important;
}

.wrap p:last-child {
  margin-top: 0.12rem;
}

.wrap button {
  font-size: 0.24rem;
  padding: 0.14rem 0.36rem;
  width: auto;
  align-self: center;
}
</style>
