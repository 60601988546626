<template>
  <section :class="$style.wrap">
    <div :class="$style.title">
      <img src="@/assets/icon_screenshorts.png" alt>
      <h3>
        游戏截图
        <small>/VIDEO·SCREENSHOTS</small>
      </h3>
    </div>
    <div :class="$style.pic" v-show="screenshots.length>0">
      <swiper :options="swiperFirstOption" ref="swiperFirstInstance">
        <swiper-slide v-for="(screenshot,index) in screenshots" :key="index">
          <img :src="screenshot" alt>
        </swiper-slide>
      </swiper>

      <swiper
        :options="swiperSecondOption"
        ref="swiperSecondInstance"
        :class="showLargeClass"
        style="display: none;"
        v-show="showLarge"
      >
        <swiper-slide v-for="(screenshot,index) in screenshots" :key="index">
          <img :src="screenshot" alt>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    screenshots: {
      required: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      // 设置两个轮播组件互相控制
      const swiperFirstInstance = this.$refs.swiperFirstInstance.swiper;
      const swiperSecondInstance = this.$refs.swiperSecondInstance.swiper;
      swiperFirstInstance.controller.control = swiperSecondInstance;
      swiperSecondInstance.controller.control = swiperFirstInstance;
    });
  },
  computed:{
    showLargeClass(){
      return this.showLarge?this.$style.toLarge:'';
    }
  },
  data() {
    return {
      swiperFirstOption: {
        freeMode: true,
        slidesPerView: 1.65,
        spaceBetween: "5%",
        on: {
          tap: event => {
            this.showLarge = !this.showLarge;
          }
        }
      },
      swiperSecondOption: {
        freeMode: true,
        slidesPerView: 1.16,
        spaceBetween: "5%",
        on: {
          tap: event => {
            this.showLarge = !this.showLarge;
          }
        }
      },
      showLarge: false
    };
  }
};
</script>

<style module>
.wrap {
  border-bottom: 0.2rem #f4f4f4 solid;
}
.title {
  margin: 0.2rem 0.2rem;
}

.title img {
  width: 0.4rem;
  height: 0.4rem;
  vertical-align: top;
}

.title h3 {
  display: inline;
  font-size: 0.32rem;
}

.title small {
  font-size: 0.24rem;
}

.pic * {
  box-sizing: border-box;
}

.pic {
  padding: 0 0.2rem;
}

/* swiper样式 */

.wrap :global(.swiper-container) {
  height: 7.36rem;
  width: 100%;
}

.wrap :global(.swiper-wrapper) {
  padding-right: 0;
}

.wrap :global(.swiper-slide) {
  font-size: 38px;
  font-weight: 700;
  background-color: #eee;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrap :global(.swiper-slide img) {
  width: 100%;
  height: 100%;
}

.to-large {
  position: fixed;
  max-width: 10rem;
  transform: translate(-50%, 0);
  top: 0;
  left: 50%;
  bottom: 0;
  height: 100% !important;
  background-color: #fff;
}

.to-large .swiper-wrapper {
  height: 11.04rem;
  top: 50%;
  margin-top: -5.52rem;
}
</style>
